<form #form="ngForm" *ngIf="taskFormInlineGroup.items">
  <dougs-field
    *ngFor="let field of taskFormInlineGroup.items; trackBy: 'id' | trackBy"
    [size]="size"
    [pattern]="field.input?.pattern || ''"
    [required]="field.isAvailable && !!field.input?.isRequired"
    class="mb-8"
    [name]="field.id"
    [(ngModel)]="field.value"
    (ngModelChange)="changeForm()"
    [field]="field"
  ></dougs-field>
  <div class="buttons" [ngClass]="{ 'buttons--right': disabledCancel }" *ngIf="form.dirty && form.valid">
    <dougs-button *ngIf="!disabledCancel" [size]="size" color="secondary" (click)="cancelForm()">
      Annuler
    </dougs-button>
    <dougs-button *ngIf="disabledCancel" [size]="size" color="secondary" (click)="closeForm()">
      Terminer plus tard
    </dougs-button>
    <dougs-button [size]="size" (click)="submitForm()">{{ disabledCancel ? 'Valider' : 'Enregistrer' }}</dougs-button>
  </div>
</form>
